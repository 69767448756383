import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import Folim from "../img/folder-symlink.svg"
import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"

const PartsPage = () => (
  <Layout>
    <Container>
      <div className="column">
        <h1 class="text-center">ЗАПЧАСТИ ДЛЯ СЕЛЬСКОХОЗЯЙСТВЕННОЙ ТЕХНИКИ</h1>
        <br /><br />
        <p>ООО «Оздокен АГРО» поставляет широкий спектр запасных частей и агрегатов к сельскохозяйственной технике. По вопросам поставки запчастей обращайтесь к нашим представителям.</p>
        <br /><br /><br /><br /><br />
        <div class="d-grid gap-2 col-6 mx-auto text-center">
          <div>
            <a className="navbar-item text-center background-dark-gray-ozdo" activeClassName="callout" href="https://disk.yandex.ru/d/w5PJ9nFFIZvwIg" target="_blank" rel="noopener noreferrer">
            <div><img src={Folim} alt="Ozdoken" style={{ height: "100px" }} /><span>&nbsp;&nbsp;&nbsp;</span></div><div><h2>Скачать каталоги запасных частей</h2></div>
            </a>
          </div>
        </div>
        <br />
{/*         <table class="table table-striped table-bordered table-hover table-responsive">
          <thead class="table-dark">
            <tr class="fw-bold">
              <td>№ п/п</td>
              <td>Наименование</td>
            </tr>
          </thead>
          <tbody>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>Агрегаты</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Лопасть для глубокорыхлителя FDP</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Механизм для внесения удобрений</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Механизм для внесения удобрений культиватор</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Полная рабочая стойка для глубокорыхлителя FDP</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>Запчасти</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>TD</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Резиновая подвеска</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Ступица</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>А</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>А010103</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Механизм сцепления с бункером A01010317</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Подшипник сцепления A01010307</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Сцепление правое в сборе </td>
            </tr>
            <tr>
              <td>10</td>
              <td>Шестерня А01010321 </td>
            </tr>
            <tr>
              <td>11</td>
              <td>Шинный механизм A01010302</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>А010104</td>
            </tr>
            <tr>
              <td>12</td>
              <td>Сцепление левое в сборе</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>А010111</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Вал оси</td>
            </tr>
            <tr>
              <td>14</td>
              <td>Колесо в сборе A010111</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Ступица в сборе A01011118</td>
            </tr>
            <tr>
              <td>16</td>
              <td>Цепная передача шины (Шина 7,50х16)</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>А010116</td>
            </tr>
            <tr>
              <td>17</td>
              <td>Пружина сошника A01011610</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>А010117</td>
            </tr>
            <tr>
              <td>18</td>
              <td>Диск в сборе А010</td>
            </tr>
            <tr>
              <td>19</td>
              <td>Сошник и труба для больших ножек</td>
            </tr>
            <tr>
              <td>20</td>
              <td>Гайка М20 A020120</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>Запчасти для Сеялка HBM</td>
            </tr>
            <tr>
              <td>21</td>
              <td>Зеленая труба</td>
            </tr>
            <tr>
              <td>22</td>
              <td>Нижняя часть зеленой трубы</td>
            </tr>
            <tr>
              <td>23</td>
              <td>Прицепное устройство для сеялки Ozdoken</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010300</td>
            </tr>
            <tr>
              <td>24</td>
              <td>Вакуумметр B01030027 </td>
            </tr>
            <tr>
              <td>25</td>
              <td>Вал трактора с8 углами для пневматической сеялки</td>
            </tr>
            <tr>
              <td>26</td>
              <td>Крыльчатка вентилятора</td>
            </tr>
            <tr>
              <td>27</td>
              <td>Полная фан-группа</td>
            </tr>
            <tr>
              <td>28</td>
              <td>Распределитель воздуха (задний)</td>
            </tr>
            <tr>
              <td>29</td>
              <td>Ремень</td>
            </tr>
            <tr>
              <td>30</td>
              <td>Шланг для вакуумного насоса B01030024</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010401</td>
            </tr>
            <tr>
              <td>31</td>
              <td>Вакуумное уплотнение</td>
            </tr>
            <tr>
              <td>32</td>
              <td>Втулка опоры зубчатой оси</td>
            </tr>
            <tr>
              <td>33</td>
              <td>Высевающий аппарат в сборе</td>
            </tr>
            <tr>
              <td>34</td>
              <td>Высевающий диск - 1,2X36</td>
            </tr>
            <tr>
              <td>35</td>
              <td>Высевающий диск - 1,7X36</td>
            </tr>
            <tr>
              <td>36</td>
              <td>Высевающий диск - 2,5X20</td>
            </tr>
            <tr>
              <td>37</td>
              <td>Высевающий диск - 2,5X26</td>
            </tr>
            <tr>
              <td>38</td>
              <td>Высевающий диск - 3,0X20</td>
            </tr>
            <tr>
              <td>39</td>
              <td>Высевающий диск - 4,5X26</td>
            </tr>
            <tr>
              <td>40</td>
              <td>Корпус семенного бункера</td>
            </tr>
            <tr>
              <td>41</td>
              <td>Крышка корпуса высевающего аппарата</td>
            </tr>
            <tr>
              <td>42</td>
              <td>Крышка семенного бункера</td>
            </tr>
            <tr>
              <td>43</td>
              <td>Опора вала</td>
            </tr>
            <tr>
              <td>44</td>
              <td>Распределитель семян</td>
            </tr>
            <tr>
              <td>45</td>
              <td>Узел B01040111</td>
            </tr>
            <tr>
              <td>46</td>
              <td>Фиксирующая шайба</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010402</td>
            </tr>
            <tr>
              <td>47</td>
              <td>Анкерная  сошник </td>
            </tr>
            <tr>
              <td>48</td>
              <td>В01040212 Botton Arm frame бушер</td>
            </tr>
            <tr>
              <td>49</td>
              <td>В01040213 Болт суппорт</td>
            </tr>
            <tr>
              <td>50</td>
              <td>В01040214 Рама блока Botton Arm</td>
            </tr>
            <tr>
              <td>51</td>
              <td>Держатели планок для грунта</td>
            </tr>
            <tr>
              <td>52</td>
              <td>Нож сошника B01040226 - (Coulter Глубокая посадка)</td>
            </tr>
            <tr>
              <td>53</td>
              <td>Пружина культиватора</td>
            </tr>
            <tr>
              <td>54</td>
              <td>Шпилька с крючком M10</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010403</td>
            </tr>
            <tr>
              <td>55</td>
              <td>Вакуумное уплотнение крышки</td>
            </tr>
            <tr>
              <td>56</td>
              <td>Внутренняя малая шайба </td>
            </tr>
            <tr>
              <td>57</td>
              <td>Высевающий аппарат в сборе В01040304</td>
            </tr>
            <tr>
              <td>58</td>
              <td>Корпус семенного бункера В01040302</td>
            </tr>
            <tr>
              <td>59</td>
              <td>Крепежная пластина </td>
            </tr>
            <tr>
              <td>60</td>
              <td>Крышка семенного бункера В01040301</td>
            </tr>
            <tr>
              <td>61</td>
              <td>Передаточный вал B01040320</td>
            </tr>
            <tr>
              <td>62</td>
              <td>Шайба (тонкая)</td>
            </tr>
            <tr>
              <td>63</td>
              <td>Шестерня B01040321 Small Helix</td>
            </tr>
            <tr>
              <td>64</td>
              <td>Шестерня B01040323 Big Helix</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010404</td>
            </tr>
            <tr>
              <td>65</td>
              <td>Вал регулировки глубины B01040419</td>
            </tr>
            <tr>
              <td>66</td>
              <td>Внешний скребок (слева)</td>
            </tr>
            <tr>
              <td>67</td>
              <td>Внешний скребок (справа)</td>
            </tr>
            <tr>
              <td>68</td>
              <td>Внутренний скребок (слева)</td>
            </tr>
            <tr>
              <td>69</td>
              <td>Внутренний скребок (справа)</td>
            </tr>
            <tr>
              <td>70</td>
              <td>Крепежная пружина</td>
            </tr>
            <tr>
              <td>71</td>
              <td>Поддерживающая гайка B01040420</td>
            </tr>
            <tr>
              <td>72</td>
              <td>Пружина рычага</td>
            </tr>
            <tr>
              <td>73</td>
              <td>Ручка регулировки глубины В01040418</td>
            </tr>
            <tr>
              <td>74</td>
              <td>Семяпровод B01040431 </td>
            </tr>
            <tr>
              <td>75</td>
              <td>Соединение для регулировки глубины</td>
            </tr>
            <tr>
              <td>76</td>
              <td>Средство для удаления комков (камнеотбрасыватель) B01040435</td>
            </tr>
            <tr>
              <td>77</td>
              <td>Средство для удаления комков (камнеотбрасыватель) B01040436</td>
            </tr>
            <tr>
              <td>78</td>
              <td>Фиксатор регулировки глубины</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010405</td>
            </tr>
            <tr>
              <td>79</td>
              <td>B01040510 Конический вал Z18</td>
            </tr>
            <tr>
              <td>80</td>
              <td>Втулка B01040509 редуктора сеялки </td>
            </tr>
            <tr>
              <td>81</td>
              <td>Заклепка В01040511</td>
            </tr>
            <tr>
              <td>82</td>
              <td>Кардан для рядного блока дискового типа В01040501</td>
            </tr>
            <tr>
              <td>83</td>
              <td>Кардан для рядного блока колтера</td>
            </tr>
            <tr>
              <td>84</td>
              <td>Коробка передач В01040503</td>
            </tr>
            <tr>
              <td>85</td>
              <td>Крышка B01040513-D вала карданного</td>
            </tr>
            <tr>
              <td>86</td>
              <td>Крышка коробки передач (левая) В01040508</td>
            </tr>
            <tr>
              <td>87</td>
              <td>Крышка коробки передач (правая) В01040506</td>
            </tr>
            <tr>
              <td>88</td>
              <td>Малый движущийся вал B01040517</td>
            </tr>
            <tr>
              <td>89</td>
              <td>Поддержка Бушера В01040512</td>
            </tr>
            <tr>
              <td>90</td>
              <td>Поддержка крышки B01040514 </td>
            </tr>
            <tr>
              <td>91</td>
              <td>Полная группа вал-редуктор B010405</td>
            </tr>
            <tr>
              <td>92</td>
              <td>Полная группа вал-редуктор B010405-D</td>
            </tr>
            <tr>
              <td>93</td>
              <td>Полная группа вал-редуктор B010405-С</td>
            </tr>
            <tr>
              <td>94</td>
              <td>Полная группа вал-редуктор без левой крышки</td>
            </tr>
            <tr>
              <td>95</td>
              <td>Полная группа валов</td>
            </tr>
            <tr>
              <td>96</td>
              <td>Рукоятка вала B01040516</td>
            </tr>
            <tr>
              <td>97</td>
              <td>Шплинт B01040518</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010407</td>
            </tr>
            <tr>
              <td>98</td>
              <td>Полное нажатие на шину</td>
            </tr>
            <tr>
              <td>99</td>
              <td>Ручка регулировки</td>
            </tr>
            <tr>
              <td>100</td>
              <td>Шина</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010409</td>
            </tr>
            <tr>
              <td>101</td>
              <td>Вал шины</td>
            </tr>
            <tr>
              <td>102</td>
              <td>Колесо регулировка глубины В010409</td>
            </tr>
            <tr>
              <td>103</td>
              <td>Ручка регулировки глубины - правая</td>
            </tr>
            <tr>
              <td>104</td>
              <td>Ручка регулировки глубины - слева</td>
            </tr>
            <tr>
              <td>105</td>
              <td>Ступица Шины - В комплекте</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010412</td>
            </tr>
            <tr>
              <td>106</td>
              <td>Нажимное Колесо</td>
            </tr>
            <tr>
              <td>107</td>
              <td>Ручка регулировки пластиковая В01041204</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010601</td>
            </tr>
            <tr>
              <td>108</td>
              <td> Ucf 6007 концентратор</td>
            </tr>
            <tr>
              <td>109</td>
              <td> Пластиковая опора пружины (радиальная)</td>
            </tr>
            <tr>
              <td>110</td>
              <td>Винтовой Штифт для натяжения Цепи</td>
            </tr>
            <tr>
              <td>111</td>
              <td>Внутренняя шина  В01060142</td>
            </tr>
            <tr>
              <td>112</td>
              <td>Натяжение коробки передач В01060111</td>
            </tr>
            <tr>
              <td>113</td>
              <td>Натяжная пружина</td>
            </tr>
            <tr>
              <td>114</td>
              <td>Натяжной Винт</td>
            </tr>
            <tr>
              <td>115</td>
              <td>Обод В01060138</td>
            </tr>
            <tr>
              <td>116</td>
              <td>Пластик Для натяжения Цепи</td>
            </tr>
            <tr>
              <td>117</td>
              <td>Пружинная Опора Пластиковая (Плоскость)</td>
            </tr>
            <tr>
              <td>118</td>
              <td>Пружинная Шайба</td>
            </tr>
            <tr>
              <td>119</td>
              <td>Распорка квадратного вала</td>
            </tr>
            <tr>
              <td>120</td>
              <td>Узел поддержки вала (крышка с подшипником) В01060113</td>
            </tr>
            <tr>
              <td>121</td>
              <td>Цепь перемещения</td>
            </tr>
            <tr>
              <td>122</td>
              <td>Цепь шины</td>
            </tr>
            <tr>
              <td>123</td>
              <td>Шина 6,5/80-15 Petlas В01060141</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В010701</td>
            </tr>
            <tr>
              <td>124</td>
              <td>Инструмент для соединения поршневого маркера</td>
            </tr>
            <tr>
              <td>125</td>
              <td>Поршень в сборе</td>
            </tr>
            <tr>
              <td>126</td>
              <td>Прижимная ручка</td>
            </tr>
            <tr>
              <td>127</td>
              <td>Соединение маркера (слева)</td>
            </tr>
            <tr>
              <td>128</td>
              <td>Соединение Рамки Маркера (Справа)</td>
            </tr>
            <tr>
              <td>129</td>
              <td>В01081108 Пластинчатый ограничитель оси</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В011101</td>
            </tr>
            <tr>
              <td>130</td>
              <td>Пластинчатый ограничитель оси В01110113</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В011201</td>
            </tr>
            <tr>
              <td>131</td>
              <td>Витая пружина (слева)</td>
            </tr>
            <tr>
              <td>132</td>
              <td>Витая пружина (Справа)</td>
            </tr>
            <tr>
              <td>133</td>
              <td>Опора Пружинного Стержня</td>
            </tr>
            <tr>
              <td>134</td>
              <td>Сошник для удобрений</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>В011204</td>
            </tr>
            <tr>
              <td>135</td>
              <td>Боковой Скребок</td>
            </tr>
            <tr>
              <td>136</td>
              <td>Витая Металлическая Стержневая Опора</td>
            </tr>
            <tr>
              <td>137</td>
              <td>Рамка Для подключения Диска</td>
            </tr>
            <tr>
              <td>138</td>
              <td>Скрученный Металлический Стержень</td>
            </tr>
            <tr>
              <td>139</td>
              <td>Соединение диска В01120401</td>
            </tr>
            <tr>
              <td>140</td>
              <td>Соединение с диском-Cлева</td>
            </tr>
            <tr>
              <td>141</td>
              <td>Соединение с диском-Справа</td>
            </tr>
            <tr>
              <td>142</td>
              <td>Соединительный Листовой Стержень</td>
            </tr>
            <tr>
              <td>143</td>
              <td>Средний Скребок</td>
            </tr>
            <tr>
              <td>144</td>
              <td>Диск в сборе В010408</td>
            </tr>
            <tr>
              <td>145</td>
              <td>Узел глазка разгрузчика  удобрений  В010902</td>
            </tr>
            <tr>
              <td>146</td>
              <td>Цепочка B01060523</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>Гарантийный</td>
            </tr>
            <tr>
              <td>147</td>
              <td>TDG скрепки</td>
            </tr>
            <tr>
              <td>148</td>
              <td>Болт M10 x 35</td>
            </tr>
            <tr>
              <td>149</td>
              <td>Бункер для удобрений В01082021</td>
            </tr>
            <tr>
              <td>150</td>
              <td>Вал группы наружный пластик</td>
            </tr>
            <tr>
              <td>151</td>
              <td>Внутренний держатель для удобрений В01082022</td>
            </tr>
            <tr>
              <td>152</td>
              <td>Гидроцилиндр для складывающейся рамы</td>
            </tr>
            <tr>
              <td>153</td>
              <td>Диски для дисковой бороны Диаметр 560</td>
            </tr>
            <tr>
              <td>154</td>
              <td>Зубчатая Окучка</td>
            </tr>
            <tr>
              <td>155</td>
              <td>Колесо FDP-6</td>
            </tr>
            <tr>
              <td>156</td>
              <td>Колесо FDP-8</td>
            </tr>
            <tr>
              <td>157</td>
              <td>Крышка Бункера Удобрений VPHE-DG8</td>
            </tr>
            <tr>
              <td>158</td>
              <td>Пластинчатая нажимная пружина</td>
            </tr>
            <tr>
              <td>159</td>
              <td>Подшипник с квадратным отверстием</td>
            </tr>
            <tr>
              <td>160</td>
              <td>Полная сборка Звездной Пластины</td>
            </tr>
            <tr>
              <td>161</td>
              <td>Профиль для колесо FDP-8</td>
            </tr>
            <tr>
              <td>162</td>
              <td>Профиль стойки</td>
            </tr>
            <tr>
              <td>163</td>
              <td>Пружина Давления Блока</td>
            </tr>
            <tr>
              <td>164</td>
              <td>Регулировка Глубины Шины - Комплект</td>
            </tr>
            <tr>
              <td>165</td>
              <td>Ролик ( Для Блока 70-75 см )</td>
            </tr>
            <tr>
              <td>166</td>
              <td>Соединение гидроцилиндра для TDG-MK48</td>
            </tr>
            <tr>
              <td>167</td>
              <td>Ступица В Сборе Для TDG TD010105-A</td>
            </tr>
            <tr>
              <td>168</td>
              <td>Ступица и диск для TDG-MK48</td>
            </tr>
            <tr>
              <td>169</td>
              <td>Шина Стабилизатора</td>
            </tr>
            <tr>
              <td>170</td>
              <td>Штифт для Соединения Отвал FDP-19</td>
            </tr>
            <tr>
              <td>171</td>
              <td>Диск турбо</td>
            </tr>
            <tr>
              <td>172</td>
              <td>Комплект</td>
            </tr>
            <tr>
              <td>173</td>
              <td>Коробка передач VPHE</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>Н</td>
            </tr>
            <tr>
              <td>174</td>
              <td>H010112170 M12х170 Болт с шестигранной головкой </td>
            </tr>
            <tr>
              <td>175</td>
              <td>Болт M16 150 x 130</td>
            </tr>
            <tr>
              <td>176</td>
              <td>Коническая шестерня Z18</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>Н0202</td>
            </tr>
            <tr>
              <td>177</td>
              <td>Гайка M16 (левая)</td>
            </tr>
            <tr>
              <td>178</td>
              <td>Гайка M16 (правая)</td>
            </tr>
            <tr>
              <td>179</td>
              <td>Гайка с уплотнителем M10</td>
            </tr>
            <tr>
              <td>180</td>
              <td>Гайка с уплотнителем M12</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>Н0301</td>
            </tr>
            <tr>
              <td>181</td>
              <td>Шайба 28х37х1</td>
            </tr>
            <tr>
              <td>182</td>
              <td>Шайба М10</td>
            </tr>
            <tr>
              <td>183</td>
              <td>Шайба Н030108</td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>Н0402</td>
            </tr>
            <tr>
              <td>184</td>
              <td>H04020520 Пружинный штифт 5 &times; 20</td>
            </tr>
            <tr>
              <td>185</td>
              <td>Пружинный штифт 6 &times; 30 H04020630 </td>
            </tr>
            <tr class="table-secondary fw-bold">
              <td><br></br></td>
              <td>Н1201</td>
            </tr>
            <tr>
              <td>186</td>
              <td>H12011623 16 и 23 шестеренчатая группа </td>
            </tr>
            <tr>
              <td>187</td>
              <td>H12011723 Z17 - Z23 Группа шестерен</td>
            </tr>
            <tr>
              <td>188</td>
              <td>Z18 Шестерня H120118</td>
            </tr>
            <tr>
              <td>189</td>
              <td>Z19 Шестерня </td>
            </tr>
            <tr>
              <td>190</td>
              <td>Z21 Шестерня</td>
            </tr>
            <tr>
              <td>191</td>
              <td>Z22 Шестерня</td>
            </tr>
            <tr>
              <td>192</td>
              <td>Рукава (воздухопровод вакуума)</td>
            </tr>
          </tbody>
        </table> */}
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
    </Container>
  </Layout>
)

export default PartsPage
